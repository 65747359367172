import { CHAIN_IDS } from 'utils/wagmi'
import CrossSwap from '../views/CrossSwap'

const CrossSwapPage = () => {
  return <CrossSwap />
}

CrossSwapPage.chains = CHAIN_IDS

export default CrossSwapPage
